<template>
  <v-dialog max-width="1200" v-model="open" persistent>
    <v-card>
      <v-card-title>
        <h2>{{ tituloModal }}</h2>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                clearable
                v-model="form.nombre"
                label="Nombre fuente financiamiento"
                @input="$v.form.nombre.$touch()"
                @blur="$v.form.nombre.$touch()"
                :error-messages="nombreErrors"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="cerrarModal" color="primary">Cerrar</v-btn>
        <v-btn @click="guardar" color="secondary">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import compraEnExteriorService from "@/services/CompraExterior.services";
import Vue from 'vue';

const FORM_INIT_VALUE = {
  id: null,
  nombre: "",
};

export default {
  name: "ModalFormFuenteFinanciamiento",
  emits: ["on-close", "on-save"],
  validations: {
    form: {
      nombre: {
        required,
      },
    },
  },
  props: {
    open: { type: Boolean, required: true },
    fuenteFinanciamiento: { type: Object },
  },
  data: () => ({
    form: {
      ...FORM_INIT_VALUE,
    },
  }),
  methods: {
    cerrarModal() {
      this.$emit("on-close");
      this.limpiarFormulario();
    },
    limpiarFormulario() {
      this.$v.$reset();
      this.form = { ...FORM_INIT_VALUE };
    },
    async guardar() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      let response = null;

      if (this.form.id) {
        response = await compraEnExteriorService.updateFuenteFinanciamiento(
          this.form.id,
          this.form
        );
      } else {
        response = await compraEnExteriorService.saveFuenteFinanciamiento(
          this.form
        );
      }

      if (response.status === 200 || response.status === 201) {
        this.$emit("on-save");
        this.limpiarFormulario();
        this.cerrarModal();
        Vue.prototype.pushAppMessage({
          show: true,
          message: response.data.message,
          type: "success",
        });
      }
    },
  },
  computed: {
    tituloModal() {
      return this.form.id
        ? "Editar fuente de financiamiento"
        : "Nueva fuente de financiamiento";
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.form.nombre.$dirty) return errors;
      !this.$v.form.nombre.required && errors.push("Nombre es requerido");
      return errors;
    },
  },
  watch: {
    fuenteFinanciamiento: {
      handler(fuenteFinanciamiento) {
        if (fuenteFinanciamiento) {
          this.form = { ...fuenteFinanciamiento };
        }
      },
      immediate: true,
    },
  },
};
</script>
