<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="open"
    persistent
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-card-title>
        <h2>Listado fuentes financiamientos</h2>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-btn
            color="primary"
            class="flex-grow-1 flex-shrink-1 mb-6"
            @click.stop="agregarFuenteFinanciamiento"
          >
            Agregar fuente financiamiento
          </v-btn>
          <data-table-component
            :headers="headers"
            :items="fuentesFinanciamiento"
            :show_loading="loading"
            :total_registros="pagination.total_elements"
            v-models:select="pagination.per_page"
            v-models:pagina="pagination.page"
            @paginar="paginar"
          >
            <template v-slot:item.activo="{ item }">
              <div class="chip-container">
                <v-chip
                  :color="item.activo ? 'success' : 'error'"
                  text-color="white"
                >
                  {{ item.activo ? "Activo" : "Inactivo" }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.acciones="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    large
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="editarFuenteFinanciamiento(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    large
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="confirmarCambioEstado(item)"
                  >
                    {{
                      item.activo
                        ? "mdi-close-circle-outline"
                        : "mdi-check-circle-outline"
                    }}
                  </v-icon>
                </template>
                <span>
                  {{ item.activo ? "Desactivar" : "Activar" }}
                </span>
              </v-tooltip>
            </template>
          </data-table-component>

          <ConfirmationDialog
            :show="modalConfirmar"
            :title="tituloConfirma"
            :message="mensajeConfirma"
            btnConfirmar="Continuar"
            @close="cerrarModalConfirmar"
            @confirm="
              fuenteFinanciamiento.activo
                ? desactivarFuenteFinanciamiento()
                : activarFuenteFinanciamiento()
            "
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="cerrarModal" color="primary">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import compraEnExteriorService from "@/services/CompraExterior.services";

const PAGINATION_INIT_VALUE = {
  page: 1,
  per_page: 10,
  total_elements: 0,
};

export default {
  name: "ModalFormFuenteFinanciamiento",
  emits: ["on-close", "open-form"],
  components: {
    DataTableComponent,
    ConfirmationDialog,
  },
  props: {
    open: { type: Boolean, required: true },
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Nombre", value: "nombre", align: "center", sortable: false },
        { text: "Estado", value: "activo", align: "center", sortable: false },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ],
      fuentesFinanciamiento: [],
      pagination: {
        ...PAGINATION_INIT_VALUE,
      },
      fuenteFinanciamiento: null,
      modalConfirmar: false,
    };
  },
  computed: {
    tituloConfirma() {
      return this.fuenteFinanciamiento?.activo
        ? "¿Desea desactivar fuente de financiamiento?"
        : "¿Desea activar fuente de financiamiento?";
    },
    mensajeConfirma() {
      return this.fuenteFinanciamiento?.activo
        ? "Al desactivar la fuente de financiamiento, no podrá ser seleccionada en futuras compras en el exterior."
        : "Al activar la fuente de financiamiento, podrá ser seleccionada en futuras compras en el exterior.";
    },
  },
  methods: {
    cerrarModal() {
      this.$emit("on-close");
    },
    asignarPaginado(pagination) {
      this.pagination = {
        page: parseInt(pagination.page),
        per_page: parseInt(pagination.per_page),
        total_elements: parseInt(pagination.total_elements),
      };
    },
    async obtenerListadoFuentesFinanciamiento() {
      this.loading = true;
      this.fuentesFinanciamiento = [];
      const { data, headers } =
        await compraEnExteriorService.getFuentesFinanciamiento(this.pagination);
      this.fuentesFinanciamiento = data;
      this.asignarPaginado({
        page: headers["page"],
        per_page: headers["per_page"],
        total_elements: headers["total_rows"],
      });
      this.loading = false;
    },
    async paginar(params) {
      this.pagination = {
        ...this.pagination,
        page: params.pagina,
        per_page: params.cantidad_por_pagina,
      };
      await this.obtenerListadoFuentesFinanciamiento();
    },
    editarFuenteFinanciamiento(fuenteFinanciamiento) {
      this.fuenteFinanciamiento = {
        ...fuenteFinanciamiento,
      };
      this.$emit("open-form", this.fuenteFinanciamiento);
    },
    agregarFuenteFinanciamiento() {
      this.fuenteFinanciamiento = null;
      this.$emit("open-form", this.fuenteFinanciamiento);
    },
    cerrarModalRegistroFuenteFinanciamiento() {
      this.fuenteFinanciamiento = null;
      this.obtenerListadoFuentesFinanciamiento();
    },
    confirmarCambioEstado(fuenteFinanciamiento) {
      this.fuenteFinanciamiento = {
        ...fuenteFinanciamiento,
      };
      console.log("this.fuenteFinanciamiento", this.fuenteFinanciamiento);
      this.modalConfirmar = true;
    },
    cerrarModalConfirmar() {
      this.modalConfirmar = false;
      this.fuenteFinanciamiento = null;
    },
    async desactivarFuenteFinanciamiento() {
      console.log("this.fuenteFinanciamiento.id", this.fuenteFinanciamiento.id);
      this.modalConfirmar = false;
      this.loading = true;
      const { status } =
        await compraEnExteriorService.desactivarFuenteFinanciamiento(
          this.fuenteFinanciamiento.id
        );
      if (status === 200) {
        this.obtenerListadoFuentesFinanciamiento();
        this.cerrarModalConfirmar();
      }
    },
    async activarFuenteFinanciamiento() {
      this.modalConfirmar = false;
      this.loading = true;
      const { status } =
        await compraEnExteriorService.activarFuenteFinanciamiento(
          this.fuenteFinanciamiento.id
        );
      if (status === 200) {
        this.obtenerListadoFuentesFinanciamiento();
        this.cerrarModalConfirmar();
      }
    },
  },
  watch: {
    open(value) {
      if (value) {
        this.obtenerListadoFuentesFinanciamiento();
      }
    },
  },
};
</script>

<style scoped>
.chip-container {
  width: 20%;
  margin: auto;
}
</style>
