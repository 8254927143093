<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center" style="gap: 16px">
          <NavButton />
          <h2 class="text-h6 text-uppercase font-weight-bold">
            Compras en el Exterior
          </h2>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div style="width: 100%; margin: 0">
          <v-btn
            v-if="haveRole('ROLE_FISCALIZADOR_COMPRAS_EXTERIOR_INSTITUCIONAL')"
            color="primary"
            class="flex-grow-1 flex-shrink-1 mb-6"
            @click.stop="modalListadoFuenteFinanciamiento = true"
          >
            Gestionar fuentes de financiamiento
          </v-btn>

          <filtros-sticky icono="mdi-filter-settings" :modo-sticky="false">
            <v-row>
              <v-col cols="12" lg="4" v-if="isFiscalizador">
                <ProgresiveLoadAutocomplete
                  v-model="filters.id_unidad"
                  :pageable="unidades"
                  :loading="unidades.isLoading"
                  class="flex-grow-1 flex-shrink-1"
                  style="min-width: 200px"
                  label="Representación"
                  placeholder="Seleccione una representación"
                  itemText="nombre"
                  itemValue="id"
                  hide-details
                  @search="buscarUnidad"
                  @load-more="cargarMasUnidades"
                  clearable
                  multiple
                />
              </v-col>

              <v-col cols="12" lg="4">
                <ProgresiveLoadAutocomplete
                  v-model="filters.id_fuente_financiamiento"
                  :pageable="fuentes"
                  :loading="fuentes.isLoading"
                  class="flex-grow-1 flex-shrink-1"
                  style="min-width: 200px"
                  label="Fuente de financiamiento"
                  placeholder="Seleccione una fuente de financiamiento"
                  itemText="nombre"
                  itemValue="id"
                  hide-details
                  @search="buscarFuenteFinanciamiento"
                  @load-more="cargarMasFuentes"
                  clearable
                />
              </v-col>

              <v-col cols="12" lg="4" v-if="isFiscalizador">
                <ProgresiveLoadAutocomplete
                  v-model="filters.id_region"
                  :pageable="regiones"
                  :loading="regiones.isLoading"
                  class="flex-grow-1 flex-shrink-1"
                  style="min-width: 200px"
                  label="Región"
                  placeholder="Seleccione una región"
                  itemText="nombre"
                  itemValue="id"
                  hide-details
                  @search="buscarRegion"
                  @load-more="cargarMasRegiones"
                  clearable
                  multiple
                />
              </v-col>

              <v-col
                cols="12"
                lg="4"
                v-if="isFiscalizador && filters.id_region"
              >
                <ProgresiveLoadAutocomplete
                  v-model="filters.id_pais"
                  :pageable="paises"
                  :loading="paises.isLoading"
                  class="flex-grow-1 flex-shrink-1"
                  style="min-width: 200px"
                  label="País"
                  placeholder="Seleccione un país"
                  itemText="nombre"
                  itemValue="id"
                  hide-details
                  @search="buscarPais"
                  @load-more="cargarMasPaises"
                  clearable
                  multiple
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  clearable
                  hide-details
                  label="Denominación de la contratación"
                  v-model="filters.denominacion"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-menu
                  ref="RangoFechas"
                  v-model="RangoFechas"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="dateRangeText"
                      label="Desde - Hasta"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <vc-date-picker
                    title="es"
                    is-range
                    v-model="fechas"
                    mode="date"
                    locale="es"
                    @input="asignarFiltrosFechas"
                  >
                  </vc-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="4">
                <div
                  class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap"
                  style="gap: 16px"
                >
                  <v-btn
                    color="primary"
                    class="flex-grow-1 flex-shrink-1"
                    x-large
                    @click.stop="actualizarFiltro"
                  >
                    Buscar
                  </v-btn>

                  <v-btn
                    color="primary"
                    class="flex-grow-1 flex-shrink-1"
                    outlined
                    x-large
                    @click.stop="restablecerBusqueda"
                  >
                    Limpiar
                  </v-btn>

                  <v-tooltip right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        class="flex-grow-1 flex-shrink-1"
                        outlined
                        x-large
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="descargarInformeComprasExterior"
                      >
                        <v-icon>mdi mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar informe PDF</span>
                  </v-tooltip>

                  <v-tooltip right color="primary" v-if="isFiscalizador">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        class="flex-grow-1 flex-shrink-1"
                        outlined
                        x-large
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="descargarExcelComprasExterior"
                      >
                        <v-icon>mdi mdi-file-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar informe excel</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </filtros-sticky>

          <v-btn
            v-if="haveRole('compra_exterior_reg_informe_crear')"
            class="mt-7"
            @click="modal = true"
            color="secondary"
          >
            Agregar registro
          </v-btn>

          <listado />

          <formularioDeRegistro :modal="modal" @cerrar="cerrarAction" />

          <ModalListadoFuentesFinanciamiento
            ref="modalListadoFuenteFinanciamiento"
            :open="modalListadoFuenteFinanciamiento"
            @on-close="modalListadoFuenteFinanciamiento = false"
            @open-form="openFormularioFuenteFinanciamiento"
          />

          <ModalFormFuenteFinanciamiento
            :open="modalFormFuenteFinanciamiento"
            @on-close="cerrarFormularioFuenteFinanciamiento"
            @on-save="actualizarListadoFuentesFinanciamiento"
            :fuenteFinanciamiento="fuenteFinanciamiento"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import NavButton from "@/components/utils/NavButton.vue";
import FiltrosSticky from "@/views/CmReporteria/components/FiltrosSticky.vue";
import listado from "@/views/CompraExterior/components/listado.vue";
import formularioDeRegistro from "@/views/CompraExterior/components/formularioDeRegistro.vue";
import ProgresiveLoadAutocomplete from "@/components/utils/ProgresiveLoadAutocomplete.vue";
import ModalListadoFuentesFinanciamiento from "@/views/CompraExterior/components/ModalListadoFuentesFinanciamiento.vue";
import ModalFormFuenteFinanciamiento from "@/views/CompraExterior/components/ModalFormFuenteFinanciamiento.vue";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import Vue from "vue";
import {
  createPageable,
  isResponseSuccesful,
  setPageableResponse,
  togglePageable,
} from "@/utils/loadable";

export default {
  name: "mainCompraExterior",
  components: {
    listado,
    formularioDeRegistro,
    NavButton,
    FiltrosSticky,
    ProgresiveLoadAutocomplete,
    ModalListadoFuentesFinanciamiento,
    ModalFormFuenteFinanciamiento,
  },
  data() {
    return {
      // Filtro fechas
      RangoFechas: false,
      fechas: { start: null, end: null },
      modal: false,
      filters: {
        id_unidad: null,
        id_region: null,
        id_pais: null,
        denominacion: null,
        fecha_desde: null,
        fecha_hasta: null,
        id_fuente_financiamiento: null,
      },
      timer: null,
      busquedas: {
        pais: null,
        region: null,
        unidad: null,
        fuente: null,
      },
      // Para el autocomplete de unidades
      unidades: createPageable([], 30),
      paginacionUnidades: {
        pagina: 1,
        registrosPorPagina: 30,
      },
      // Para el autocomplete de regiones
      regiones: createPageable([], 30),
      paginacionRegiones: {
        pagina: 1,
        registrosPorPagina: 30,
      },
      // Para el autocomplete de países
      paises: createPageable([], 30),
      paginacionPaises: {
        pagina: 1,
        registrosPorPagina: 30,
      },
      // Para el autocomplete de fuentes de financiamiento
      fuentes: createPageable([], 30),
      paginacionFuentes: {
        pagina: 1,
        registrosPorPagina: 30,
      },
      // Modales Fuente de Financiamiento
      modalListadoFuenteFinanciamiento: false,
      modalFormFuenteFinanciamiento: false,
      fuenteFinanciamiento: {},
    };
  },
  methods: {
    ...mapActions("compraExterior", [
      "getListadoRegistros",
      "getInstituciones",
      "setFiltroPais",
      "setFiltroUnidad",
      "setFiltroRegion",
      "setFiltroDenominacion",
      "setFiltroFechaInicio",
      "setFiltroFechaFin",
      "setFiltroFuenteFinanciamiento",
      "descargarInformeComprasExterior",
    ]),
    asignarFiltrosFechas() {
      if (this.fechas && this.fechas.start && this.fechas.end) {
        this.filters.fecha_desde = moment(this.fechas.start).format(
          "YYYY-MM-DD"
        );
        this.filters.fecha_hasta = moment(this.fechas.end).format("YYYY-MM-DD");
      } else {
        this.filters.fecha_desde = null;
        this.filters.fecha_hasta = null;
      }
    },
    async cerrarAction() {
      this.modal = false;
      await this.getListadoRegistros();
    },
    async actualizarFiltro() {
      await this.setFiltroUnidad(this.filters?.id_unidad);
      await this.setFiltroRegion(this.filters?.id_region);
      await this.setFiltroPais(this.filters?.id_pais);
      await this.setFiltroDenominacion(this.filters?.denominacion);
      await this.setFiltroFechaInicio(this.filters?.fecha_desde);
      await this.setFiltroFechaFin(this.filters?.fecha_hasta);
      await this.setFiltroFuenteFinanciamiento(
        this.filters?.id_fuente_financiamiento
      );
      await this.getListadoRegistros();
    },
    async restablecerBusqueda() {
      (this.fechas = {
        start: null,
        end: null,
      }),
        (this.filters = {
          id_unidad: null,
          id_region: null,
          id_pais: null,
          denominacion: null,
          fecha_desde: null,
          fecha_hasta: null,
          id_fuente_financiamiento: null,
        });
      await this.setFiltroPais(null);
      await this.setFiltroUnidad(null);
      await this.setFiltroRegion(null);
      await this.setFiltroDenominacion(null);
      await this.setFiltroFechaInicio(null);
      await this.setFiltroFechaFin(null);
      await this.setFiltroFuenteFinanciamiento(null);
      await this.getListadoRegistros();
    },
    // Métodos para el manejo de regiones
    async buscarRegion(termino) {
      if (this.busquedas.region === termino) return;

      this.busquedas.region = termino;
      this.regiones.data = [];
      this.paginacionRegiones.pagina = 1;

      this.getRegiones();
    },
    async getRegiones() {
      const filtros = {
        pagination: true,
        per_page: this.paginacionRegiones.registrosPorPagina,
        page: this.paginacionRegiones.pagina,
        nombre: this.busquedas.region,
      };

      togglePageable(this.regiones);
      const data = await this.services.Paises.getListaRegiones(filtros);

      const copiaRegiones = this.regiones.data;

      setPageableResponse(this.regiones, data, data.headers, {
        skipOnSuccess: true,
        persistDataOnError: true,
      });

      if (!isResponseSuccesful(data)) {
        const { pagina } = this.paginacionRegiones;
        this.paginacionRegiones.pagina = pagina > 1 ? pagina - 1 : 1;
        return;
      }

      this.regiones.data = copiaRegiones.concat(data.data);
    },
    cargarMasRegiones() {
      const { page, per_page, total_rows } = this.regiones.pagination;
      if (page * per_page >= total_rows) return;

      this.paginacionRegiones.pagina = page + 1;
      this.getRegiones();
    },
    // Métodos para el manejo de paises
    async buscarPais(termino) {
      if (this.busquedas.pais === termino) return;

      this.busquedas.pais = termino;
      this.paises.data = [];
      this.paginacionPaises.pagina = 1;

      this.getPaises();
    },
    async getPaises() {
      const filtros = {
        pagination: true,
        per_page: this.paginacionPaises.registrosPorPagina,
        page: this.paginacionPaises.pagina,
        region: this.filters.id_region,
        nombre: this.busquedas.pais,
      };

      togglePageable(this.paises);
      const data = await this.services.Paises.getListaPaises(filtros);

      const copiaPaises = this.paises.data;

      setPageableResponse(this.paises, data, data.headers, {
        skipOnSuccess: true,
        persistDataOnError: true,
      });

      if (!isResponseSuccesful(data)) {
        const { pagina } = this.paginacionPaises;
        this.paginacionPaises.pagina = pagina > 1 ? pagina - 1 : 1;
        return;
      }

      this.paises.data = copiaPaises.concat(data.data);
    },
    cargarMasPaises() {
      const { page, per_page, total_rows } = this.paises.pagination;
      if (page * per_page >= total_rows) return;

      this.paginacionPaises.pagina = page + 1;
      this.getPaises();
    },
    // Método para el manejo de unidades por id de institución
    async buscarUnidad(termino) {
      if (this.busquedas.unidad === termino) return;

      this.busquedas.unidad = termino;
      this.unidades.data = [];
      this.paginacionUnidades.pagina = 1;

      this.getUnidadesInstitucion();
    },
    async getUnidadesInstitucion() {
      const filtros = {
        pagination: true,
        per_page: this.paginacionUnidades.registrosPorPagina,
        page: this.paginacionUnidades.pagina,
        id_institucion: this.userInfo?.user?.id_institucion,
        nombre: this.busquedas.unidad,
      };

      togglePageable(this.unidades);

      const data = await this.services.compraEnExterior.unidadesInstitucion(
        filtros
      );

      const copiaUnidadesInstitucion = this.unidades.data;

      setPageableResponse(this.unidades, data, data.headers);

      if (!isResponseSuccesful(data)) {
        const { pagina } = this.paginacionUnidades;
        this.paginacionUnidades.pagina = pagina > 1 ? pagina - 1 : 1;
        return;
      }

      this.unidades.data = copiaUnidadesInstitucion.concat(data.data);
    },
    cargarMasUnidades() {
      const { page, per_page, total_rows } = this.unidades.pagination;
      if (page * per_page >= total_rows) return;

      this.paginacionUnidades.pagina = page + 1;
      this.getUnidadesInstitucion();
    },
    async buscarFuenteFinanciamiento(termino) {
      if (this.busquedas.fuente === termino) return;

      this.busquedas.fuente = termino;
      this.fuentes.data = [];
      this.paginacionFuentes.pagina = 1;

      this.getFuentes();
    },
    async getFuentes() {
      const filtros = {
        pagination: true,
        per_page: this.paginacionFuentes.registrosPorPagina,
        page: this.paginacionFuentes.pagina,
        nombre: this.busquedas.fuente,
        catalogo: true,
      };

      togglePageable(this.fuentes);
      const data =
        await this.services.compraEnExterior.getFuentesFinanciamiento(filtros);

      const copiaFuentes = this.fuentes.data;

      setPageableResponse(this.fuentes, data, data.headers, {
        skipOnSuccess: true,
        persistDataOnError: true,
      });

      if (!isResponseSuccesful(data)) {
        const { pagina } = this.paginacionFuentes;
        this.paginacionFuentes.pagina = pagina > 1 ? pagina - 1 : 1;
        return;
      }

      this.fuentes.data = copiaFuentes.concat(data.data);
    },
    async cargarMasFuentes() {
      const { page, per_page, total_rows } = this.fuentes.pagination;
      if (page * per_page >= total_rows) return;

      this.paginacionFuentes.pagina = page + 1;
      this.getFuentes();
    },
    openFormularioFuenteFinanciamiento(fuenteFinanciamiento) {
      if (fuenteFinanciamiento) {
        this.fuenteFinanciamiento = {
          ...fuenteFinanciamiento,
        };
      }
      this.modalFormFuenteFinanciamiento = true;
    },
    cerrarFormularioFuenteFinanciamiento() {
      this.fuenteFinanciamiento = {};
      this.modalFormFuenteFinanciamiento = false;
    },
    async actualizarListadoFuentesFinanciamiento() {
      this.cerrarFormularioFuenteFinanciamiento();
      await this.$refs.modalListadoFuenteFinanciamiento.obtenerListadoFuentesFinanciamiento();
    },
    async descargarExcelComprasExterior() {
      const filtros = {
        id_institucion: this.filters?.id_institucion,
        id_pais: this.filters?.id_pais,
        id_region: this.filters?.id_region,
        id_unidad: this.filters?.id_unidad,
        denominacion: this.filters?.denominacion,
        fecha_inicio: this.filters?.fecha_desde,
        fecha_fin: this.filters?.fecha_hasta,
        pagination: false,
      };

      Vue.prototype.showLoader();

      const { data } =
        await this.services.compraEnExterior.descargarExcelCompras(filtros);

      Vue.prototype.hideLoader();

      const blob = new Blob([data], { type: "application/vnd.ms-excel" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "compras_exterior.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  computed: {
    ...mapState(["userInfo"], "compraExterior", ["instituciones", "paises"]),
    dateRangeText() {
      return this.fechas && this.fechas.start && this.fechas.end
        ? moment(this.fechas.start).format("D/M/Y") +
            " - " +
            moment(this.fechas.end).format("D/M/Y")
        : "Selecciona un rango de fechas";
    },
    isFiscalizador() {
      return this.haveRole("ROLE_FISCALIZADOR_COMPRAS_EXTERIOR_INSTITUCIONAL");
    },
  },
  async created() {
    await this.getUnidadesInstitucion();
    await this.getRegiones();
    await this.getFuentes();
  },

  watch: {
    "filters.id_region": function (val) {
      if (!val) {
        this.filters.id_pais = null;
      } else {
        this.busquedas.pais = null;
        this.paises.data = [];
        this.paginacionPaises.pagina = 1;
        this.getPaises();
      }
    },
  },
};
</script>
